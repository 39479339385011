'use strict';

/**
* Reusable slick carousel configurations
* @example - $('.product-carousel').slick(slickConfigs.pdp)
*/

module.exports = {
    hero: {
        autoplay: true,
        autoplaySpeed: 5000,
        easing: 'swing',
        infinite: true,
        speed: 800,
        dots: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
    },
    productTiles: {
        infinite: true,
        speed: 300,
        dots: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1
    },
    homeProductShelves: {
        infinite: false,
        speed: 300,
        dots: false,
        arrows: true,
        slidesToShow: 5.5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4.5,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    },
    pdp: {
        infinite: true,
        speed: 400,
        dots: false,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
    },
    banner: {
        infinite: false,
        slidesToShow: 2.2,
        swipeToSlide: true,
        arrows: false,
        dots: false
    },
    detail: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        adaptiveHeight: true,
        speed: 300,
        asNavFor: '.product-carousel-nav'
    },
    detailNav: {
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.product-carousel',
        dots: true,
        centerMode: true,
        focusOnSelect: true,
        arrows: false,
        vertical: true
    }
};
